import { useTranslation } from '@eti/providers';
import { css, etiColors } from '@eti/styles';
import SubPage from './SubPage';

const privacyPolicyStyles = css`
  a {
    text-decoration: none;
  }

  h2 {
    margin-top: calc(2.5rem + 10px);
    padding: 1rem 0;
    a {
      transition: 300ms ease all;
    }
  }

  dl {
    padding: 1rem 0;
    dd {
      font-size: 1rem;
      padding: 0.5rem 0;
      a {
        padding: 0.5rem 0;
        transition: 300ms ease all;
        &:hover {
          background: ${etiColors.info.focus};
          border-radius: 4px;
          box-shadow:
            0 10px 15px -3px rgb(0 0 0 / 10%),
            0 4px 6px -2px rgb(0 0 0 / 5%);
          color: ${etiColors.white};
          padding: 0.5rem;
          text-decoration: none;
        }
      }
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 1.275rem;
    margin-top: 0.85rem;

    &:nth-of-type(7) {
      margin-bottom: 3rem;
    }
    &:nth-of-type(8) {
      margin-top: 3rem;
    }
  }

  .etiHtmlContent > div {
    box-shadow:
      0 20px 25px -5px rgb(0 0 0 / 10%),
      0 10px 10px -5px rgb(0 0 0 / 4%);
  }

  table {
    border: none;
    margin-bottom: 3rem;
    width: 100%;
  }

  tbody {
    tr {
      font-size: 1.1rem;
      &:nth-child(1) {
        background: ${etiColors.info.focus};
        th {
          color: ${etiColors.white};
          padding: 1rem;
        }
      }
      td {
        padding: 1rem;
      }
    }
  }

  li {
    margin: 1.5rem 0;
    strong {
      background: ${etiColors.info.focus};
      border-radius: 4px;
      box-shadow:
        0 4px 6px -1px rgb(0 0 0 / 10%),
        0 2px 4px -1px rgb(0 0 0 / 6%);
      color: ${etiColors.white};
      padding: 0.2rem;
    }
  }

  ol {
    margin: 2rem 0;
  }

  ol:first-of-type {
    li {
      font-weight: 600;
    }
  }
`;

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      className={privacyPolicyStyles}
      image="privacy-policy"
      pageTitle={t('PageTitle.rf.privacy-policy.action')}
      shouldShowPrintButton
      textKey="PrivacyPolicy.Text"
    />
  );
};

export default PrivacyPolicyPage;

import { useState } from 'react';
import { useProperty } from '@eti/providers';
import TripReminderGraphqlContainer from './TripReminderGraphqlContainer';

interface TripReminderContainerProps {
  className?: string;
}

const TripReminderContainer = ({ className }: TripReminderContainerProps) => {
  const { p } = useProperty();
  const [showReminder, setShowReminder] = useState(true);

  const shouldShowReminder =
    p('IbeClient.TripReminder.Enabled') &&
    showReminder &&
    Boolean(!JSON.parse(sessionStorage.getItem('hideTripReminder') ?? 'null'));

  const handleDiscardReminder = () => {
    sessionStorage.setItem('hideTripReminder', '1');
    setShowReminder(false);
  };

  if (shouldShowReminder) {
    return (
      <TripReminderGraphqlContainer
        className={className}
        onDiscardReminder={handleDiscardReminder}
      />
    );
  }
  return null;
};

export default TripReminderContainer;

import { price as priceUtils } from '@eti/utils';
import { CHECK_IN_BAGGAGE } from '../../../products';
import { getPriceDivided as getPricePerBound } from '../../../common/utils/price';
const PRESELECTION_VALUES = {
  none: 'NONE',
  yes: 'YES_SELECTED',
  no: 'NO_SELECTED'
};
export const getPreselected = travelers => {
  return travelers === null || travelers === void 0 ? void 0 : travelers.some(traveler => (traveler === null || traveler === void 0 ? void 0 : traveler.productPreSelection) === PRESELECTION_VALUES.yes);
};
export const getTravelersPrimaryFlow = order => {
  var _order$sellSpecificat, _order$sellSpecificat2, _order$travelers;
  const baggageSpecification = (_order$sellSpecificat = order === null || order === void 0 || (_order$sellSpecificat2 = order.sellSpecification) === null || _order$sellSpecificat2 === void 0 ? void 0 : _order$sellSpecificat2.sellPriceBaggage) !== null && _order$sellSpecificat !== void 0 ? _order$sellSpecificat : [];
  return order === null || order === void 0 || (_order$travelers = order.travelers) === null || _order$travelers === void 0 ? void 0 : _order$travelers.map(traveler => {
    var _price$markup$value, _price$markup, _price$price$value, _price$price;
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      id: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '',
      productPreSelection: order.productPreSelection || '',
      markup: (_price$markup$value = price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) !== null && _price$markup$value !== void 0 ? _price$markup$value : null,
      price: (_price$price$value = price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value) !== null && _price$price$value !== void 0 ? _price$price$value : 0
    };
  });
};
export const getTravelersPostbooking = (productId, order) => {
  var _order$availableExtra, _baggageProduct$sellS, _order$travelers2;
  const baggageProduct = order === null || order === void 0 || (_order$availableExtra = order.availableExtraProducts) === null || _order$availableExtra === void 0 ? void 0 : _order$availableExtra.find(product => String(product === null || product === void 0 ? void 0 : product.id) === String(productId));
  const baggageSpecification = (baggageProduct === null || baggageProduct === void 0 || (_baggageProduct$sellS = baggageProduct.sellSpecification) === null || _baggageProduct$sellS === void 0 ? void 0 : _baggageProduct$sellS.sellPriceBaggage) || [];
  const eligibleTravelerIdsForBaggage = baggageSpecification.flatMap(product => product === null || product === void 0 ? void 0 : product.travelerId).filter(Boolean) || [];
  return order === null || order === void 0 || (_order$travelers2 = order.travelers) === null || _order$travelers2 === void 0 || (_order$travelers2 = _order$travelers2.filter(_ref => {
    let {
      id
    } = _ref;
    return id !== undefined && eligibleTravelerIdsForBaggage.length && (eligibleTravelerIdsForBaggage === null || eligibleTravelerIdsForBaggage === void 0 ? void 0 : eligibleTravelerIdsForBaggage.includes(String(id)));
  })) === null || _order$travelers2 === void 0 ? void 0 : _order$travelers2.map(traveler => {
    var _price$markup$value2, _price$markup2, _price$price2;
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      name: (traveler === null || traveler === void 0 ? void 0 : traveler.fullName) || '',
      markup: (_price$markup$value2 = price === null || price === void 0 || (_price$markup2 = price.markup) === null || _price$markup2 === void 0 ? void 0 : _price$markup2.value) !== null && _price$markup$value2 !== void 0 ? _price$markup$value2 : null,
      price: price === null || price === void 0 || (_price$price2 = price.price) === null || _price$price2 === void 0 ? void 0 : _price$price2.value
    };
  });
};
export const getBaggageWeight = _ref2 => {
  let {
    numberOfPieces,
    weight,
    weightUnit,
    texts,
    t
  } = _ref2;
  if (numberOfPieces && weight && weightUnit) {
    const {
      pieces,
      weightDescription,
      weightValues
    } = texts;
    const baggageWeightValues = `${t(pieces, numberOfPieces)} x ${t(weightValues, weight, weightUnit)}`;
    return t(weightDescription, baggageWeightValues);
  }
  return '';
};
export const getSecondBagPrice = (shouldShowPricePerBoundSecondBag, numberOfBounds, currentSecondBagTraveler) => {
  return priceUtils.formatPrice(shouldShowPricePerBoundSecondBag ? getPricePerBound(currentSecondBagTraveler === null || currentSecondBagTraveler === void 0 ? void 0 : currentSecondBagTraveler.price, numberOfBounds) : currentSecondBagTraveler === null || currentSecondBagTraveler === void 0 ? void 0 : currentSecondBagTraveler.price);
};
export const formCheckedBaggageWeightSizeDescription = _ref3 => {
  let {
    weight,
    weightUnit,
    numberOfPieces,
    t
  } = _ref3;
  const weightDescription = weight ? t('Product.BaggagePerTraveler.Weight', weight, weightUnit) : '';
  const piecesDescription = numberOfPieces ? `${t('Product.BaggagePerTraveler.Pieces', numberOfPieces)} x` : '';
  const baggageWeightDescription = `${piecesDescription !== null && piecesDescription !== void 0 ? piecesDescription : ''} ${weightDescription}`;
  return baggageWeightDescription.trim();
};
export const formCombinedBagWeightDescription = _ref4 => {
  let {
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  } = _ref4;
  const weightDescription = weight === weightOfSecondBag && weightUnit === weightUnitOfSecondBag ? t('Product.BaggagePerTraveler.Weight', weightOfSecondBag, weightUnitOfSecondBag) : '';
  const piecesDescription = weight === weightOfSecondBag && numberOfPieces ? `${t('Product.BaggagePerTraveler.Pieces', numberOfPieces + (piecesOfSecondBag || 0))} x` : '';
  const combinedBagWeightDescription = `${piecesDescription !== null && piecesDescription !== void 0 ? piecesDescription : ''} ${weightDescription}`;
  return combinedBagWeightDescription.trim();
};
export const formSeparateBagWeightDescription = _ref5 => {
  let {
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  } = _ref5;
  const checkedBagPieceText = t('Product.BaggagePerTraveler.Pieces', numberOfPieces);
  const checkedBagWeightText = t('Product.BaggagePerTraveler.Weight', weight, weightUnit);
  const secondBagPieceText = t('Product.BaggagePerTraveler.Pieces', piecesOfSecondBag);
  const secondBagWeightText = t('Product.BaggagePerTraveler.Weight', weightOfSecondBag, weightUnitOfSecondBag);
  const separateBagWeightDescription = `${checkedBagPieceText} x ${checkedBagWeightText} + ${secondBagPieceText} x ${secondBagWeightText}`;
  return separateBagWeightDescription.trim();
};
export const generateCheckedBaggageContent = (titleChoiceNo, baggageDescription, productPrice, travelerId, markUp) => [{
  id: `content-false-${travelerId}`,
  label: titleChoiceNo,
  priceRaw: 0,
  value: 'false',
  markUp: 0
}, {
  id: `content-true-${travelerId}`,
  label: baggageDescription,
  priceRaw: productPrice,
  value: 'true',
  markUp
}];
export const generateCombinedBaggageContent = _ref6 => {
  let {
    titleChoiceNo,
    baggageDescription,
    productPrice,
    travelerId,
    combinedBagPrice,
    combinedBagDescription,
    secondBagProductId,
    markup
  } = _ref6;
  return [{
    id: `content-false-${travelerId}`,
    label: titleChoiceNo,
    priceRaw: 0,
    markup: 0,
    value: 'false'
  }, {
    id: `content-singlebag-true-${travelerId}`,
    label: baggageDescription,
    priceRaw: productPrice,
    value: 'true',
    markup
  }, {
    id: `content-combinedbag-true-${travelerId}`,
    label: combinedBagDescription,
    priceRaw: combinedBagPrice,
    secondBagProductId,
    value: 'combined',
    markup
  }];
};
export const checkedAndSecondBagOptionData = _ref7 => {
  var _traveler$markup, _traveler$markup2;
  let {
    checkedBagWeight,
    checkedBagWeightUnit,
    checkedBagNumberOfPieces,
    traveler,
    isBaggageAvailableOrIncluded,
    titleChoiceNo,
    secondBagTraveler,
    secondBagProductId,
    weightOfSecondBag,
    piecesOfSecondBag,
    weightUnitOfSecondBag,
    t
  } = _ref7;
  const baggageDescription = formCheckedBaggageWeightSizeDescription({
    weight: checkedBagWeight,
    weightUnit: checkedBagWeightUnit,
    numberOfPieces: checkedBagNumberOfPieces,
    t
  });
  const combinedBagDescription = piecesOfSecondBag && piecesOfSecondBag > 0 && checkedBagWeight === weightOfSecondBag ? formCombinedBagWeightDescription({
    weight: checkedBagWeight,
    weightUnit: checkedBagWeightUnit,
    numberOfPieces: checkedBagNumberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  }) : formSeparateBagWeightDescription({
    weight: checkedBagWeight,
    weightUnit: checkedBagWeightUnit,
    numberOfPieces: checkedBagNumberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  });
  const baggageRadioButtonContent = piecesOfSecondBag && piecesOfSecondBag > 0 ? generateCombinedBaggageContent({
    titleChoiceNo,
    baggageDescription,
    productPrice: traveler === null || traveler === void 0 ? void 0 : traveler.price,
    markup: (_traveler$markup = traveler === null || traveler === void 0 ? void 0 : traveler.markup) !== null && _traveler$markup !== void 0 ? _traveler$markup : 0,
    travelerId: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '',
    combinedBagPrice: ((secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price) || 0) + ((traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0),
    combinedBagDescription,
    secondBagProductId
  }) : generateCheckedBaggageContent(titleChoiceNo, baggageDescription, (traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0, traveler === null || traveler === void 0 ? void 0 : traveler.id, (_traveler$markup2 = traveler === null || traveler === void 0 ? void 0 : traveler.markup) !== null && _traveler$markup2 !== void 0 ? _traveler$markup2 : 0);
  const baggageContents = !isBaggageAvailableOrIncluded ? [] : baggageRadioButtonContent;
  return {
    baggageContents
  };
};
export const bundleBaggageDetails = bundleData => {
  var _bundleData$options, _bundleContents$conte;
  const bundleContents = bundleData === null || bundleData === void 0 || (_bundleData$options = bundleData.options) === null || _bundleData$options === void 0 ? void 0 : _bundleData$options.find(option => (option === null || option === void 0 ? void 0 : option.id) !== undefined && option.id === bundleData.selectedOption);
  return bundleContents === null || bundleContents === void 0 || (_bundleContents$conte = bundleContents.content) === null || _bundleContents$conte === void 0 ? void 0 : _bundleContents$conte.find(bundleContent => (bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.productTypeId) === CHECK_IN_BAGGAGE.id.toString());
};
export const checkedBaggageBundlePiecesWeight = bundleData => {
  const bundleCheckedBaggageDetails = bundleBaggageDetails(bundleData);
  const {
    pieces,
    weight,
    weightUnit
  } = bundleCheckedBaggageDetails || {};
  return {
    pieces: pieces !== null && pieces !== void 0 ? pieces : undefined,
    weight: weight !== null && weight !== void 0 ? weight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined
  };
};
export const checkedBaggageIncludedPiecesWeight = includedBaggage => {
  const {
    pieces,
    weight,
    weightUnit
  } = includedBaggage !== null && includedBaggage !== void 0 ? includedBaggage : {};
  return {
    pieces: pieces !== null && pieces !== void 0 ? pieces : null,
    weight: weight !== null && weight !== void 0 ? weight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined
  };
};
export const checkedBaggagePurchasablePiecesWeight = sellSpecification => {
  var _sellSpecification$se;
  const {
    numberOfUnits,
    maxWeight,
    weightUnit
  } = (_sellSpecification$se = sellSpecification === null || sellSpecification === void 0 ? void 0 : sellSpecification.sellPriceBaggage[0]) !== null && _sellSpecification$se !== void 0 ? _sellSpecification$se : {};
  return {
    pieces: numberOfUnits,
    weight: maxWeight !== null && maxWeight !== void 0 ? maxWeight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined
  };
};
export const getIncludedBaggagePiecesWeightPrimaryFlow = (includedBaggage, bundleData, t) => {
  var _includedBaggage$weig, _includedBaggage$weig2, _includedBaggage$piec;
  const bundleBaggageData = checkedBaggageBundlePiecesWeight(bundleData);
  const heaviness = (_includedBaggage$weig = includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.weight) !== null && _includedBaggage$weig !== void 0 ? _includedBaggage$weig : bundleBaggageData === null || bundleBaggageData === void 0 ? void 0 : bundleBaggageData.weight;
  const heavinessUnit = (_includedBaggage$weig2 = includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.weightUnit) !== null && _includedBaggage$weig2 !== void 0 ? _includedBaggage$weig2 : bundleBaggageData === null || bundleBaggageData === void 0 ? void 0 : bundleBaggageData.weightUnit;
  const weightDescription = heaviness && t('Product.BaggagePerTraveler.Weight', heaviness, heavinessUnit);
  const includedBaggagePieces = ((includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.pieces) || (bundleBaggageData === null || bundleBaggageData === void 0 ? void 0 : bundleBaggageData.pieces)) && `${t('Product.BaggagePerTraveler.Pieces', (_includedBaggage$piec = includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.pieces) !== null && _includedBaggage$piec !== void 0 ? _includedBaggage$piec : bundleBaggageData === null || bundleBaggageData === void 0 ? void 0 : bundleBaggageData.pieces)} x`;
  const includedBaggagePiecesAndWeight = `${includedBaggagePieces !== null && includedBaggagePieces !== void 0 ? includedBaggagePieces : ''} ${weightDescription}`;
  return includedBaggagePieces ? includedBaggagePiecesAndWeight.trim() : undefined;
};
import { useCallback, useRef, useState } from 'react';
import { breakpoints, Button, Card, Inline, vars } from '@etg/wings';
import { useProperty, useTranslation, useSiteContext, useDirection } from '@eti/providers';
import { useAuth } from '@eti/authentication';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { constants } from '@eti/utils';
import Media from 'react-media';
import { useHistory, useLocation } from 'react-router-dom';
import { observeClickOutside } from 'react-ref-observe';
import { UserCircle, SignOut, CaretDown, CaretUp } from '@phosphor-icons/react';
import { AuthenticationType } from '@eti/schema-types';
import { useApolloClient } from '@apollo/client';
import { routes } from '../../../constants/routesAndApisConstants';
import { asyncInit } from '../../../utils/facebook';
import { CONNECTED, SUCCESS } from '../constants';
import MyBookingsModal from '../containers/MyBookingsModal';
import { closeWebMessagesChat } from '../../chat/webMessages/utils';

interface MyBookingsButtonProps {
  showOnlyLargeButton?: boolean;
  iconOnly?: boolean;
}
const brandedStyles = (brandCode: string) => {
  const { brands } = constants;

  switch (brandCode) {
    case brands.AZULMUNDO: {
      return css`
        background-color: ${vars.colors.button.primary.default.base};
        border-color: #fff;
        color: ${vars.colors.button.primary.default.label};
        white-space: nowrap;

        &:hover {
          background-color: ${vars.colors.button.primary.hover.base};
        }

        &:active {
          background-color: ${vars.colors.button.primary.active.base};
        }

        &:hover,
        &:active {
          border-color: #fff;
          color: ${vars.colors.button.primary.default.label};
        }
      `;
    }
    case brands.GOTOGATE:
    case brands.FLIGHTNETWORK:
      return css`
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        white-space: nowrap;

        &:hover {
          background-color: #fff;
          border-color: #000;
          color: #000;
        }
      `;
    case brands.MYTRIP: {
      return css`
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
        white-space: nowrap;

        &:hover {
          background-color: #000;
          border-color: #fff;
          color: #fff;
        }
      `;
    }
    case brands.ADR: {
      return css`
        border-color: #000;
        color: #000;

        &:hover {
          background-color: #f7ba88;
          border-color: #000;
          color: #000;
        }
      `;
    }
    default:
      return css`
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 3px;
        color: #000;
        white-space: nowrap;

        &:hover {
          background-color: #000;
          border-color: #fff;
          color: #fff;
        }
      `;
  }
};

const buttonsStyles = (showOnlyLargeButton?: boolean) => css`
  ${showOnlyLargeButton && `width: 100%`};
  padding: 12px 16px;
`;

const myBookingsBtnSmallStyles = css`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  &:focus-visible {
    border-radius: 16px;
    color: ${vars.colors.links.focus};
    outline: 2px solid ${vars.colors.focusRing};
  }
`;

const wrapperStyles = css`
  position: relative;

  @media print {
    display: none;
  }
`;

const iconWrapperStyles = css`
  display: flex;
  position: relative;
`;

const dropdownStyles = (showOnlyLargeButton?: boolean) => css`
  background-color: #fff;
  border: 1px solid ${vars.colors.divider};
  border-radius: 3px;
  margin-top: 3px;
  ${showOnlyLargeButton ? `inset-inline-start: 0` : `inset-inline-end: 0`};
  position: absolute;
  white-space: nowrap;
  ${showOnlyLargeButton ? `width: 100%` : `width: auto`};
  z-index: 2;

  @media (min-width: ${breakpoints._970}) {
    width: 100%;
  }
`;

const dropdownItemStyles = css`
  ${resetButtonStyle}; /* stylelint-disable-next-line */
  color: ${vars.colors.text};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: none;
  width: 100%;
`;

const circleStyles = css`
  background-color: ${vars.colors.signal.positive.light};
  border: solid 1px #fff;
  border-radius: 50%;
  height: 10px;
  inset-block-start: -2px;
  inset-inline-end: 0;
  position: absolute;
  width: 10px;
`;

const circleSmallStyles = css`
  inset-block-start: 2px;
`;

const userLoginStatusStyles = css`
  button[data-testid='myBookings-button-large'] {
    height: 48px;
    padding: 12px 8px;

    + div {
      width: auto;
    }
  }

  span {
    gap: 4px;
  }
`;

const MyBookingsButton = ({ showOnlyLargeButton, iconOnly }: MyBookingsButtonProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const history = useHistory();
  const { isRTL } = useDirection();
  const { pathname } = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isMyBookingsButtonEnabled = !p('IbeClient.HideMyBookingsButton.Enabled');
  const isMyBookingsModalEnabled = p('IbeClient.MyBookingsModal.Enabled');

  const { user, logoutUser } = useAuth();
  const client = useApolloClient();

  const {
    language: { code: languageCode },
    market: { code: marketCode },
    brand: { code: brandCode },
  } = useSiteContext();
  const facebookClientID = p('IbeClient.MyBookings.Facebook.Client.Id');

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLoginModalDismiss = useCallback(() => {
    setIsLoginModalOpen(false);
  }, []);

  observeClickOutside([ref], closeDropdown);

  const routesToRedirectToOrderLogin = [
    routes.ORDER,
    routes.ORDER_STATUS,
    routes.ORDER_LIST,
    routes.ORDER_DETAILS,
    routes.CHANGE_TRIP,
  ];

  const isPostbookingProductsPage = pathname.includes(routes.POSTBOOKING_PRODUCTS);

  const redirectToStartPage = () => {
    history.push(routes.START);
  };

  const redirectToLoginPage = () => {
    history.push(routes.ORDER_AUTHENTICATION);
  };

  const cleanupAndRedirect = async () => {
    if (document.getElementById('webMessagesScript')) {
      closeWebMessagesChat();
    }
    if (routesToRedirectToOrderLogin.some((route) => pathname.includes(route))) {
      if (isMyBookingsModalEnabled) {
        redirectToStartPage();
      } else {
        redirectToLoginPage();
      }
    }

    if (isPostbookingProductsPage) {
      history.push(routes.POSTBOOKING_AUTHENTICATION);
    }
    await client.clearStore();
  };

  const isAuthenticationPage =
    pathname === routes.ORDER_AUTHENTICATION ||
    pathname === routes.POSTBOOKING_AUTHENTICATION ||
    pathname === routes.RYANAIR_ORDER_AUTHENTICATION;

  const handleClick = () => {
    if (!user) {
      if (isMyBookingsModalEnabled && !isAuthenticationPage) {
        setIsLoginModalOpen(true);
      } else {
        redirectToLoginPage();
      }
    } else if (isDropdownOpen) {
      closeDropdown();
    } else {
      setIsDropdownOpen(true);
    }
  };

  const handleShowBookings = () => {
    closeDropdown();
    history.push(routes.ORDER_LIST);
  };

  const facebookLogout = () => {
    asyncInit(facebookClientID, languageCode, marketCode, () => {
      window.FB.getLoginStatus((response) => {
        if (response.status === CONNECTED) {
          window.FB.logout(() => {
            cleanupAndRedirect();
          });
        } else {
          cleanupAndRedirect();
        }
      });
    });
  };

  const handleLogout = async () => {
    closeDropdown();

    try {
      const { data: logoutData } = await logoutUser();

      if (logoutData?.logoutUser?.response === SUCCESS) {
        if (user?.loginType === AuthenticationType.Facebook) {
          facebookLogout();
        } else {
          cleanupAndRedirect();
        }
      }
    } catch (error) {
      console.error('Error during logout:', error); // eslint-disable-line no-console
      throw error;
    }
  };

  return (
    <div
      className={cx(wrapperStyles, user && userLoginStatusStyles)}
      data-testid="my-bookings-button-header"
      ref={ref}
    >
      {isMyBookingsButtonEnabled && (
        <Media query={`(min-width: ${breakpoints._970})`}>
          {(matches) =>
            matches || showOnlyLargeButton ? (
              <Button
                aria-label={iconOnly ? t('SiteHeader.My.Bookings.Button') : undefined}
                className={cx(brandedStyles(brandCode), buttonsStyles(showOnlyLargeButton))}
                data-testid="myBookings-button-large"
                onClick={handleClick}
              >
                <div className={iconWrapperStyles}>
                  {user ? (
                    <>
                      <UserCircle size={24} weight="fill" />
                      <div className={circleStyles} />
                    </>
                  ) : (
                    <UserCircle size={24} />
                  )}
                </div>
                {!iconOnly && <span>{t('SiteHeader.My.Bookings.Button')}</span>}
                {user && (
                  <>
                    {isDropdownOpen ? (
                      <CaretUp size={20} weight="bold" />
                    ) : (
                      <CaretDown size={20} weight="bold" />
                    )}
                  </>
                )}
              </Button>
            ) : (
              <button
                aria-label={t('Postbooking.Login.Header.Title')}
                className={myBookingsBtnSmallStyles}
                data-testid="myBookings-button-small"
                onClick={handleClick}
                type="button"
              >
                <div className={iconWrapperStyles}>
                  {user ? (
                    <>
                      <UserCircle color={vars.colors.header.content} size={28} weight="fill" />
                      <div className={cx(circleStyles, circleSmallStyles)} />
                    </>
                  ) : (
                    <UserCircle color={vars.colors.header.content} size={28} />
                  )}
                </div>
              </button>
            )
          }
        </Media>
      )}
      {isDropdownOpen && (
        <Card className={dropdownStyles(showOnlyLargeButton)}>
          <Card.Section>
            <Inline
              as="button"
              className={dropdownItemStyles}
              data-testid="myBookings-see-order"
              onClick={handleShowBookings}
            >
              {t('Postbooking.Show.Bookings.Button')}
            </Inline>
          </Card.Section>
          <Card.Section>
            <Inline
              as="button"
              className={dropdownItemStyles}
              data-testid="myBookings-logout"
              onClick={handleLogout}
              spacing={8}
            >
              <SignOut mirrored={!isRTL} size={18} weight="regular" />
              {t('Postbooking.Logout.Button')}
            </Inline>
          </Card.Section>
        </Card>
      )}
      <MyBookingsModal isLoginModalOpen={isLoginModalOpen} onDismiss={handleLoginModalDismiss} />
    </div>
  );
};

export default MyBookingsButton;

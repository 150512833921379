import { string, getTravelerInitials } from '@eti/utils';
import { seatMap } from '../../index';
// segmentId is a string of two numbers, first one is the bound index and the second the segment index
// "10" would be the segment id for the first segment of the second bound
const getSegmentIndex = segmentId => segmentId.length === 2 ? segmentId[1] : segmentId;
const getSegmentDefaultTravelerIds = segment => {
  return segment !== null && segment !== void 0 && segment.__typename && (segment.__typename === 'SellSeatingPreference' || segment.__typename === 'SellSeatingBeside') ? [0] : [];
};
const transformSellSpecificationSegment = sellSpecificationSegment => {
  return sellSpecificationSegment && {
    ...sellSpecificationSegment,
    travelerIds: sellSpecificationSegment.travelerIds ? sellSpecificationSegment.travelerIds.map(Number) : getSegmentDefaultTravelerIds(sellSpecificationSegment)
  };
};
const getBounds = _ref => {
  let {
    bounds,
    sellSpecification
  } = _ref;
  return bounds.map(_ref2 => {
    let {
      boundId,
      segments = []
    } = _ref2;
    const sellSpecificationBound = sellSpecification === null || sellSpecification === void 0 ? void 0 : sellSpecification.sellSeatMapBounds.find(sellBound => (sellBound === null || sellBound === void 0 ? void 0 : sellBound.id) === boundId);
    const tripSegments = segments.filter(segment => segment.__typename === 'TripSegment');
    return {
      ...sellSpecificationBound,
      boundIndex: Number(boundId),
      boundName: {
        from: {
          city: tripSegments[0].origin.cityName
        },
        to: {
          city: tripSegments[tripSegments.length - 1].destination.cityName
        }
      },
      sellSeatMapSegments: tripSegments.map(segment => {
        const sellSpecificationSegment = sellSpecificationBound ? sellSpecificationBound.sellSeatMapSegments.find(sellSegment => (sellSegment === null || sellSegment === void 0 ? void 0 : sellSegment.id) === segment.segmentId) : undefined;
        const segmentIndex = Number(getSegmentIndex(segment.segmentId));
        return {
          ...transformSellSpecificationSegment(sellSpecificationSegment),
          boundSegmentIndex: `${boundId}-${segmentIndex}`,
          segmentIndex,
          from: {
            city: segment.origin.cityName,
            iata: segment.origin.code
          },
          to: {
            city: segment.destination.cityName,
            iata: segment.destination.code
          }
        };
      })
    };
  });
};
const transformDeck = deck => {
  return {
    ...deck,
    cabins: (deck.cabins || []).map(cabin => ({
      ...cabin,
      rows: cabin === null || cabin === void 0 ? void 0 : cabin.rows.map(row => ({
        ...row,
        sellSeatMapSeats: ((row === null || row === void 0 ? void 0 : row.sellSeatMapSeats) || []).map(seat => {
          var _seat$price, _seat$price2;
          return {
            ...seat,
            markup: seat === null || seat === void 0 || (_seat$price = seat.price) === null || _seat$price === void 0 || (_seat$price = _seat$price.markup) === null || _seat$price === void 0 ? void 0 : _seat$price.value,
            price: seat === null || seat === void 0 || (_seat$price2 = seat.price) === null || _seat$price2 === void 0 ? void 0 : _seat$price2.price.value
          };
        })
      }))
    }))
  };
};
export const transformSeatmapPrices = function (bounds) {
  if (bounds === void 0) {
    bounds = [];
  }
  return bounds.map(bound => {
    return {
      ...bound,
      sellSeatMapSegments: bound.sellSeatMapSegments.map(segment => ({
        ...segment,
        lowerDeck: transformDeck((segment === null || segment === void 0 ? void 0 : segment.lowerDeck) || {}),
        mainDeck: transformDeck((segment === null || segment === void 0 ? void 0 : segment.mainDeck) || {}),
        upperDeck: transformDeck((segment === null || segment === void 0 ? void 0 : segment.upperDeck) || {})
      }))
    };
  });
};
const getBoundsForPostbooking = _ref3 => {
  let {
    availableExtraProducts,
    bounds
  } = _ref3;
  const seatMapProduct = availableExtraProducts.find(_ref4 => {
    let {
      id
    } = _ref4;
    return parseInt(id, 10) === seatMap.id;
  });
  if (!seatMapProduct) {
    return [];
  }
  const {
    sellSpecification: seatMapSellSpecification
  } = seatMapProduct;
  const sellSpecification = {
    ...seatMapSellSpecification,
    sellSeatMapBounds: transformSeatmapPrices(seatMapSellSpecification.sellSeatMapBounds)
  };
  return getBounds({
    bounds,
    sellSpecification
  });
};
export const getData = _ref5 => {
  let {
    availableExtraProducts,
    bounds,
    sellSpecification
  } = _ref5;
  return availableExtraProducts ? getBoundsForPostbooking({
    availableExtraProducts,
    bounds
  }) : getBounds({
    bounds,
    sellSpecification
  });
};
const getCurrentTravelers = (travelers, travelerFormNames, defaultTravelerLabel, travelerFormData) => {
  return travelers.map(traveler => {
    const currentTravelerNames = travelerFormNames.find(travelerFormName => travelerFormName.travelerId === traveler.id);
    const gender = traveler.id != null ? travelerFormData[Number(traveler.id)] : {};
    return {
      name: currentTravelerNames ? currentTravelerNames.fullName : string.insertArgument(defaultTravelerLabel, Number(traveler.id) + 1),
      id: Number(traveler.id),
      ageType: traveler.ageType,
      initials: (currentTravelerNames === null || currentTravelerNames === void 0 ? void 0 : currentTravelerNames.initials) || '',
      ...gender
    };
  });
};
export const getTravelers = (order, travelerFormNames, defaultTravelerLabel, travelerFormData) => {
  return order.availableExtraProducts ? order.travelers.map(_ref6 => {
    let {
      id,
      ...traveler
    } = _ref6;
    return {
      ...traveler,
      ...(id ? {
        id: Number(id)
      } : {}),
      initials: getTravelerInitials(traveler.firstName, traveler.lastName),
      name: traveler.fullName
    };
  }) : getCurrentTravelers(order.travelers, travelerFormNames, defaultTravelerLabel, travelerFormData);
};
import { constants } from '@eti/utils';
import { getTravelersPrimaryFlow, bundleBaggageDetails, getPreselected, checkedBaggagePurchasablePiecesWeight } from './checkInBaggageUtils';
export const useCheckedBag = _ref => {
  let {
    travelerNumber,
    order,
    bundleData,
    includedBaggage
  } = _ref;
  const travelers = getTravelersPrimaryFlow(order);
  const traveler = (travelers === null || travelers === void 0 ? void 0 : travelers[travelerNumber]) || {};
  const isInfant = traveler.ageType === constants.ageTypes.INFANT;
  const isFirstTraveler = travelerNumber === 0;
  const {
    pieces: checkedBagNumberOfPieces = 0,
    weight: checkedBagWeight = 0,
    weightUnit: checkedBagWeightUnit = ''
  } = checkedBaggagePurchasablePiecesWeight(order.sellSpecification);
  const includedCheckedBaggageInTrip = includedBaggage || bundleBaggageDetails(bundleData);
  const isCheckedBaggageIncludedInTrip = includedCheckedBaggageInTrip && Object.keys(includedCheckedBaggageInTrip).length !== 0;
  const isCheckedBagPreselected = getPreselected(travelers);
  return {
    checkedBagNumberOfPieces,
    checkedBagWeight,
    checkedBagWeightUnit,
    isInfant,
    isFirstTraveler,
    isCheckedBaggageIncludedInTrip,
    traveler,
    travelers,
    isCheckedBagPreselected
  };
};
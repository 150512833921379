import { getUpsellProps } from '../../common/utils/productCardProps';
import { formatUpsell } from '../../common/utils/upsellParse';
import { UPSELL_TYPES } from '../../common/utils/constants';
export const getFastTrackUpsell = (upSellText, airportList) => {
  var _upSellObject$fullyAv, _upSellObject$partial;
  const upSellObject = formatUpsell(upSellText);
  if (!airportList || !(upSellObject !== null && upSellObject !== void 0 && (_upSellObject$fullyAv = upSellObject.fullyAvailable) !== null && _upSellObject$fullyAv !== void 0 && _upSellObject$fullyAv.trim()) || !((_upSellObject$partial = upSellObject.partiallyAvailable) !== null && _upSellObject$partial !== void 0 && _upSellObject$partial.trim())) {
    return getUpsellProps(upSellText);
  }
  let shouldDisplayUpsell = false;
  if (airportList.length > 0) {
    var _upSellObject$type;
    const hasUnavailable = airportList.some(airport => !airport.available);
    const hasAvailable = airportList.some(airport => airport.available);
    upSellObject.content = hasUnavailable ? upSellObject.partiallyAvailable : upSellObject.fullyAvailable;
    upSellObject.type = (_upSellObject$type = upSellObject.type) !== null && _upSellObject$type !== void 0 ? _upSellObject$type : UPSELL_TYPES.INFOMATION;
    shouldDisplayUpsell = hasAvailable;
  }
  const isUpsellHighlight = shouldDisplayUpsell && (upSellObject === null || upSellObject === void 0 ? void 0 : upSellObject.type) === UPSELL_TYPES.PROMOTIONAL;
  return {
    upSellObject,
    isUpsellHighlight,
    shouldDisplayUpsell
  };
};
export const validateAvailabilityTranslation = availabilityProps => {
  try {
    const {
      availabilityCard,
      cart
    } = availabilityProps;
    const {
      availabilityTitle,
      departing,
      return: returnKey,
      availableStatus,
      unavailableStatus,
      addedStatus,
      statusTooltip,
      addedTitle
    } = availabilityCard;
    const {
      purchasedMessage
    } = cart;
    return Boolean(availabilityTitle && departing && returnKey && availableStatus && unavailableStatus && addedStatus && statusTooltip && addedTitle && purchasedMessage);
  } catch (error) {
    return false;
  }
};
const getAirports = order => order.bounds.flatMap(bound => {
  var _bound$segments;
  return bound === null || bound === void 0 || (_bound$segments = bound.segments) === null || _bound$segments === void 0 ? void 0 : _bound$segments.filter(segment => (segment === null || segment === void 0 ? void 0 : segment.__typename) === 'TripSegment').map(tripSegment => {
    var _tripSegment$origin, _tripSegment$origin2;
    return {
      code: (_tripSegment$origin = tripSegment.origin) === null || _tripSegment$origin === void 0 ? void 0 : _tripSegment$origin.code,
      name: (_tripSegment$origin2 = tripSegment.origin) === null || _tripSegment$origin2 === void 0 ? void 0 : _tripSegment$origin2.name
    };
  });
});
export const getAirportList = (order, list) => {
  const boundAirports = getAirports(order);
  return list === null || list === void 0 ? void 0 : list.map(availability => {
    const airportIndex = boundAirports.findIndex(b => b.code === availability.departureAirport);
    if (airportIndex !== -1) {
      const {
        code,
        name
      } = boundAirports[airportIndex];
      return {
        ...availability,
        departureAirport: `${name} (${code})`
      };
    }
    return availability;
  });
};
import { Switch } from 'react-router-dom';
import { useProperty } from '@eti/providers';
import { routes } from '../../constants/routesAndApisConstants';
import PageNotFound from '../../pages/status-pages/components/PageNotFound';
import { PaymentLinkPage, PaymentPage } from '../../pages/payment';
import PostBookingLoginPage from '../../pages/post-booking/authentication';
import PostBookingProductsPage from '../../pages/post-booking/ancillary-store/entry-page';
import ConsentPage from '../../pages/consent';
import StartPage from '../../pages/start';
import ReceiptPage from '../../pages/receipt';
import OrderDetailsPage from '../../pages/order-details';
import ChangeTripPage from '../../pages/change-trip';
import MetaEntryPage from '../../pages/meta-entry';
import TravelerDetailsPage from '../../pages/traveler-details';
import BookingStepPage from '../../pages/booking-step';
import ProcessingOrderPage from '../../pages/subpages/components/ProcessingOrderPage';
import ProcessingPixPage from '../../pages/processing-pix/ProcessingPixPage';
import ProcessingUpiPage from '../../pages/processing-upi';
import ResultPage from '../../pages/result';
import CookiesPage from '../../pages/subpages/components/CookiesPage';
import CancellationProtectionPage from '../../pages/subpages/components/CancellationProtectionPage';
import ContactUsPage from '../../pages/contact-us';
import ContactUsOrderListPage from '../../pages/contact-us-order-list';
import AboutUsPage from '../../pages/subpages/components/AboutUsPage';
import AncillaryStorePage from '../../pages/subpages/components/AncillaryStorePage';
import BaggageInsurancePage from '../../pages/subpages/components/BaggageInsurancePage';
import BcomBusinessTravelConditionsPage from '../../pages/subpages/components/BcomBusinessTravelConditionsPage';
import ConnectionGuaranteePage from '../../pages/subpages/components/ConnectionGuaranteePage';
import CoronaVirusPage from '../../pages/subpages/components/CoronaVirusPage';
import ImpressumPage from '../../pages/subpages/components/ImpressumPage';
import TravelConditionsPage from '../../pages/subpages/components/TravelConditionsPage';
import TravelConditionsExternalPage from '../../pages/subpages/components/TravelConditionsExternalPage';
import TravelInsurancePage from '../../pages/subpages/components/TravelInsurancePage';
import TrustedChoicePage from '../../pages/subpages/components/TrustedChoicePage';
import Experience from '../../pages/subpages/components/Experience';
import CmsContentPage from '../../pages/subpages/components/CmsContentPage';
import PrivacyPolicyPage from '../../pages/subpages/components/PrivacyPolicyPage';
import PrivacyPolicyExternalPage from '../../pages/subpages/components/PrivacyPolicyExternalPage';
import SelfTransferPage from '../../pages/subpages/components/SelfTransferPage';
import SustainabilityPage from '../../pages/subpages/components/SustainabilityPage';
import PriceDropProtectionPage from '../../pages/subpages/components/PriceDropProtectionPage';
import TermsAfterBookingPage from '../../pages/subpages/components/TermsAfterBookingPage';
import PaymentCascadingTransitionPage from '../../pages/payment-cascading-transition';
import { ScrollToTop } from '../../common/custom-hooks/scrolling';
import { InvoiceReceiptPdf, ReceiptPdf } from '../../pages/receipt-pdf';
import MobileAppDownloadPage from '../../pages/subpages/components/MobileAppDownloadPage';
import PackageTravelInfoPage from '../../pages/subpages/components/PackageTravelInfoPage';
import withPageWrappers from '../../infrastructure/page-wrappers/WithPageWrappers';
import Route from '../../common/routing/components/Route';
import Login from '../../common/authentication';
import RefundsPage from '../../pages/subpages/components/RefundsPage';
import DestinationPage from '../../pages/destination';
import YourSelectedTripPage from '../../pages/your-selected-trip';
import NewBrandRedirectionPage from '../../pages/new-brand-redirection';
import MtpLoginPage from '../../pages/mobile-travel-plan/components/MtpLoginPage';
import MtpSelectFlightPage from '../../pages/mobile-travel-plan/components/MtpSelectFlightPage';
import MtpFlightStatusPage from '../../pages/mobile-travel-plan/components/MtpFlightStatusPage';
import ClosedDownBrandPage from '../../pages/subpages/components/ClosedDownBrandPage';
import DisputeResolutionProcedurePage from '../../pages/subpages/components/DisputeResolutionProcedurePage';
import { useQualtricsExitModal } from '../../common/custom-hooks/useQualtricsExitModal';
import MyBookings from '../../pages/my-bookings';
import LoginRoute from '../../common/routing/components/LoginRoute';
import ContactUsRoute from '../../common/routing/components/ContactUsRoute';
import VerifyUserRoute from '../../common/routing/components/VerifyUserRoute';
import OrderStatusPage from '../../pages/order-status';
import ChallengePage from '../../pages/challenge';
import CFCReviewPage from '../../pages/carrier-flight-confirmation-review';
import CFCIframePage from '../../pages/carrier-flight-confirmation-iframe/components/CFCIframePage';

const AboutUsPageWithErrorBoundary = withPageWrappers(AboutUsPage);
const AncillaryStorePageWithErrorBoundary = withPageWrappers(AncillaryStorePage);
const BaggageInsurancePageWithErrorBoundary = withPageWrappers(BaggageInsurancePage);
const BcomBusinessTravelConditionsPageWithErrorBoundary = withPageWrappers(
  BcomBusinessTravelConditionsPage,
);
const ConsentPageWithErrorBoundary = withPageWrappers(ConsentPage);
const ConnectionGuaranteePageWithErrorBoundary = withPageWrappers(ConnectionGuaranteePage);
const CancellationProtectionPageWithErrorBoundary = withPageWrappers(CancellationProtectionPage);
const CookiesPageWithErrorBoundary = withPageWrappers(CookiesPage);
const ContactPageWithErrorBoundary = withPageWrappers(ContactUsPage);
const ContactUsOrderListPageWithErrorBoundary = withPageWrappers(ContactUsOrderListPage);
const CoronaVirusPageWithErrorBoundary = withPageWrappers(CoronaVirusPage);
const PostBookingProductsPageWithErrorBoundary = withPageWrappers(PostBookingProductsPage);
const ImpressumPagePageWithErrorBoundary = withPageWrappers(ImpressumPage);
const MetaEntryPageWithErrorBoundary = withPageWrappers(MetaEntryPage);
const ResultPageWithErrorBoundary = withPageWrappers(ResultPage);
const TrustedChoicePageWithErrorBoundary = withPageWrappers(TrustedChoicePage);
const ExperienceWithErrorBoundary = withPageWrappers(Experience);
const PageNotFoundWithErrorBoundary = withPageWrappers(PageNotFound);
const PaymentPageWithErrorBoundary = withPageWrappers(PaymentPage);
const PaymentLinkPageWithErrorBoundary = withPageWrappers(PaymentLinkPage);
const PaymentCascadingTransitionPageWithErrorBoundary = withPageWrappers(
  PaymentCascadingTransitionPage,
);
const CmsContentPageWithErrorBoundary = withPageWrappers(CmsContentPage);
const PostBookingLoginPageWithErrorBoundary = withPageWrappers(PostBookingLoginPage);
const ProcessingOrderPageWithErrorBoundary = withPageWrappers(ProcessingOrderPage);
const ProcessingPixPageWithErrorBoundary = withPageWrappers(ProcessingPixPage);
const ProcessingUpiPageWithErrorBoundary = withPageWrappers(ProcessingUpiPage);
const ReceiptPageWithErrorBoundary = withPageWrappers(ReceiptPage);
const MyBookingsWelcomePageWithErrorBoundary = withPageWrappers(MyBookings);
const OrderDetailsPageWithErrorBoundary = withPageWrappers(OrderDetailsPage);
const ChangeTripPageWithErrorBoundary = withPageWrappers(ChangeTripPage);
const OrderLoginPageWithErrorBoundary = withPageWrappers(Login);
const OrderStatusPageWithErrorBoundary = withPageWrappers(OrderStatusPage);
const StartPageWithErrorBoundary = withPageWrappers(StartPage);
const TravelerDetailsPageWithErrorBoundary = withPageWrappers(TravelerDetailsPage);
const BookingStepPageWithErrorBoundary = withPageWrappers(BookingStepPage);
const PrivacyPolicyPageWithErrorBoundary = withPageWrappers(PrivacyPolicyPage);
const PrivacyPolicyExternalPageWithErrorBoundary = withPageWrappers(PrivacyPolicyExternalPage);
const SelfTransferPageWithErrorBoundary = withPageWrappers(SelfTransferPage);
const SustainabilityPageWithErrorBoundary = withPageWrappers(SustainabilityPage);
const PriceDropProtectionPageWithErrorBoundary = withPageWrappers(PriceDropProtectionPage);
const TravelConditionsPageWithErrorBoundary = withPageWrappers(TravelConditionsPage);
const TravelConditionsExternalPageWithErrorBoundary = withPageWrappers(
  TravelConditionsExternalPage,
);
const TravelInsurancePageWithErrorBoundary = withPageWrappers(TravelInsurancePage);
const TermsAfterBookingPageWithErrorBoundary = withPageWrappers(TermsAfterBookingPage);
const InvoiceReceiptPdfPageWithErrorBoundary = withPageWrappers(InvoiceReceiptPdf);
const ReceiptPdfPageWithErrorBoundary = withPageWrappers(ReceiptPdf);
const MobileAppDownloadPageWithErrorBoundary = withPageWrappers(MobileAppDownloadPage);
const PackageTravelInfoPageWithErrorBoundary = withPageWrappers(PackageTravelInfoPage);
const RefundsPageWithErrorBoundary = withPageWrappers(RefundsPage);
const DestinationPageWithErrorBoundary = withPageWrappers(DestinationPage);
const YourSelectedTripPageWithErrorBoundary = withPageWrappers(YourSelectedTripPage);
const NewBrandRedirectionPageWithErrorBoundary = withPageWrappers(NewBrandRedirectionPage);
const MtpLoginPageWithErrorBoundary = withPageWrappers(MtpLoginPage);
const MtpSelectFlightPageWithErrorBoundary = withPageWrappers(MtpSelectFlightPage);
const MtpFlightStatusPageWithErrorBoundary = withPageWrappers(MtpFlightStatusPage);
const ClosedDownBrandPageWithErrorBoundary = withPageWrappers(ClosedDownBrandPage);
const DisputeResolutionProcedurePageWithErrorBoundry = withPageWrappers(
  DisputeResolutionProcedurePage,
);
const ChallengePageWithErrorBoundary = withPageWrappers(ChallengePage);
const CFCReviewPageWithErrorBoundary = withPageWrappers(CFCReviewPage);
const CFCIframePageWithErrorBoundary = withPageWrappers(CFCIframePage);

interface ContentProps {
  headerAndInfoHeight: number;
  setIsMenuVisible: (pathname: { type: string }) => void;
}
const Content = ({ headerAndInfoHeight, setIsMenuVisible }: ContentProps) => {
  const { searchFormUrl, selectionKey } = window.spa_variables;
  const { p } = useProperty();
  const isOrderStatusPageEnabled = p('OrderStatusPage.Enabled');

  useQualtricsExitModal();
  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.PAYMENT && (
          <Route
            component={PaymentPageWithErrorBoundary}
            exact
            path={routes.PAYMENT}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.PAYMENT_LINK && (
          <Route
            component={PaymentLinkPageWithErrorBoundary}
            exact
            path={routes.PAYMENT_LINK}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.PAYMENT_CASCADING_TRANSITION && (
          <Route
            component={PaymentCascadingTransitionPageWithErrorBoundary}
            exact
            path={routes.PAYMENT_CASCADING_TRANSITION}
            props={{ setIsMenuVisible }}
          />
        )}
        <LoginRoute
          component={PostBookingLoginPageWithErrorBoundary}
          exact
          path={routes.POSTBOOKING_AUTHENTICATION}
          redirectTo={routes.POSTBOOKING_PRODUCTS}
          setIsMenuVisible={setIsMenuVisible}
        />
        <Route
          component={PostBookingProductsPageWithErrorBoundary}
          dataLayerPathname="/postbooking/products"
          exact
          path={routes.POSTBOOKING_PRODUCTS}
          props={{ setIsMenuVisible }}
        />
        {routes.START && (
          <Route
            component={StartPageWithErrorBoundary}
            exact
            path={[routes.START, '/']}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.META_ENTRY && (
          <Route
            component={MetaEntryPageWithErrorBoundary}
            exact
            path={routes.META_ENTRY}
            props={{
              searchFormUrl,
              selectionKey,
              setIsMenuVisible,
            }}
          />
        )}
        {routes.RESULT && (
          <Route
            component={ResultPageWithErrorBoundary}
            exact
            path={routes.RESULT}
            props={{ setIsMenuVisible, selectionKey }}
          />
        )}
        {routes.TRAVELER_DETAILS && (
          <Route
            component={TravelerDetailsPageWithErrorBoundary}
            exact
            path={routes.TRAVELER_DETAILS}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_TWO && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_TWO}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_THREE && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_THREE}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_FOUR && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_FOUR}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_FIVE && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_FIVE}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_SIX && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_SIX}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.STEP_SEVEN && (
          <Route
            component={BookingStepPageWithErrorBoundary}
            exact
            path={routes.STEP_SEVEN}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        <Route
          component={ConsentPageWithErrorBoundary}
          exact
          path={routes.CONSENT}
          props={{ setIsMenuVisible }}
        />
        <VerifyUserRoute
          component={ReceiptPageWithErrorBoundary}
          exact
          path={routes.ORDER}
          props={{ setIsMenuVisible }}
          {...(isOrderStatusPageEnabled ? { redirectTo: routes.ORDER_STATUS } : {})}
        />
        <Route
          component={OrderStatusPageWithErrorBoundary}
          exact
          path={routes.ORDER_STATUS}
          props={{ setIsMenuVisible }}
        />
        {routes.ORDER_LIST && (
          <Route
            component={MyBookingsWelcomePageWithErrorBoundary}
            exact
            path={routes.ORDER_LIST}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.ORDER_DETAILS && (
          <Route
            component={OrderDetailsPageWithErrorBoundary}
            path={routes.ORDER_DETAILS}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CHANGE_TRIP && (
          <Route
            component={ChangeTripPageWithErrorBoundary}
            exact
            path={routes.CHANGE_TRIP}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PROCESSING_ORDER && (
          <Route
            component={ProcessingOrderPageWithErrorBoundary}
            exact
            path={routes.PROCESSING_ORDER}
            props={{ setIsMenuVisible }}
            {...(isOrderStatusPageEnabled ? { redirectTo: routes.ORDER_STATUS } : {})}
          />
        )}
        {routes.PROCESSING_PIX && (
          <Route
            component={ProcessingPixPageWithErrorBoundary}
            exact
            path={routes.PROCESSING_PIX}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PROCESSING_UPI && (
          <Route
            component={ProcessingUpiPageWithErrorBoundary}
            exact
            path={routes.PROCESSING_UPI}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.COOKIES && (
          <Route
            component={CookiesPageWithErrorBoundary}
            exact
            path={routes.COOKIES}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CONTACT && (
          <ContactUsRoute
            component={ContactPageWithErrorBoundary}
            contactUsOrderListComponent={ContactUsOrderListPageWithErrorBoundary}
            exact
            path={routes.CONTACT}
            props={{
              pageTitle: 'PageTitle.rf.contact.action',
              privacyPolicyTextKey: 'ContactUs.Form.Conditions.Label.Text',
            }}
            setIsMenuVisible={setIsMenuVisible}
          />
        )}
        {routes.CORONA && (
          <Route
            component={CoronaVirusPageWithErrorBoundary}
            exact
            path={routes.CORONA}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.ANCILLARY_STORE && (
          <Route
            component={AncillaryStorePageWithErrorBoundary}
            exact
            path={routes.ANCILLARY_STORE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.TRAVEL_CONDITIONS && (
          <Route
            component={TravelConditionsPageWithErrorBoundary}
            exact
            path={routes.TRAVEL_CONDITIONS}
            props={{ headerAndInfoHeight, setIsMenuVisible }}
          />
        )}
        {routes.SELF_TRANSFER && (
          <Route
            component={SelfTransferPageWithErrorBoundary}
            exact
            path={routes.SELF_TRANSFER}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.SUSTAINABILITY && (
          <Route
            component={SustainabilityPageWithErrorBoundary}
            exact
            path={routes.SUSTAINABILITY}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PRIVACY_POLICY && (
          <Route
            component={PrivacyPolicyPageWithErrorBoundary}
            exact
            path={routes.PRIVACY_POLICY}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PRICE_DROP_PROTECTION && (
          <Route
            component={PriceDropProtectionPageWithErrorBoundary}
            exact
            path={routes.PRICE_DROP_PROTECTION}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.ORDER_AUTHENTICATION && (
          <LoginRoute
            component={OrderLoginPageWithErrorBoundary}
            exact
            path={routes.ORDER_AUTHENTICATION}
            redirectTo={routes.ORDER_LIST}
            setIsMenuVisible={setIsMenuVisible}
          />
        )}
        {routes.RYANAIR_ORDER_AUTHENTICATION && (
          <LoginRoute
            component={OrderLoginPageWithErrorBoundary}
            exact
            isRyanairOrder
            path={routes.RYANAIR_ORDER_AUTHENTICATION}
            redirectTo={routes.ORDER_DETAILS_MANAGE_TRIP}
            setIsMenuVisible={setIsMenuVisible}
          />
        )}
        {routes.INVOICE_RECEIPT_PDF && (
          <Route
            component={InvoiceReceiptPdfPageWithErrorBoundary}
            exact
            path={routes.INVOICE_RECEIPT_PDF}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.RECEIPT_PDF && (
          <Route
            component={ReceiptPdfPageWithErrorBoundary}
            exact
            path={routes.RECEIPT_PDF}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.TRAVEL_INSURANCE && (
          <Route
            component={TravelInsurancePageWithErrorBoundary}
            exact
            path={routes.TRAVEL_INSURANCE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CANCELLATION_PROTECTION && (
          <Route
            component={CancellationProtectionPageWithErrorBoundary}
            exact
            path={routes.CANCELLATION_PROTECTION}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.BAGGAGE_INSURANCE && (
          <Route
            component={BaggageInsurancePageWithErrorBoundary}
            exact
            path={routes.BAGGAGE_INSURANCE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CLOSED_DOWN_BRAND && (
          <Route
            component={ClosedDownBrandPageWithErrorBoundary}
            exact
            path={routes.CLOSED_DOWN_BRAND}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.DISPUTE_RESOLUTION_PROCEDURE && (
          <Route
            component={DisputeResolutionProcedurePageWithErrorBoundry}
            exact
            path={routes.DISPUTE_RESOLUTION_PROCEDURE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CONNECTION_GUARANTEE && (
          <Route
            component={ConnectionGuaranteePageWithErrorBoundary}
            exact
            path={routes.CONNECTION_GUARANTEE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.TERMS_AFTER_BOOKING && (
          <Route
            component={TermsAfterBookingPageWithErrorBoundary}
            exact
            path={routes.TERMS_AFTER_BOOKING}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.IMPRESSUM && (
          <Route
            component={ImpressumPagePageWithErrorBoundary}
            exact
            path={routes.IMPRESSUM}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.ABOUT_US && (
          <Route
            component={AboutUsPageWithErrorBoundary}
            exact
            path={routes.ABOUT_US}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.TRUSTED_CHOICE && (
          <Route
            component={TrustedChoicePageWithErrorBoundary}
            exact
            path={routes.TRUSTED_CHOICE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.EXPERIENCE && (
          <Route
            component={ExperienceWithErrorBoundary}
            exact
            path={routes.EXPERIENCE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.TRAVEL_CONDITIONS_EXTERNAL && (
          <Route
            component={TravelConditionsExternalPageWithErrorBoundary}
            exact
            path={routes.TRAVEL_CONDITIONS_EXTERNAL}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PRIVACY_POLICY_EXTERNAL && (
          <Route
            component={PrivacyPolicyExternalPageWithErrorBoundary}
            exact
            path={routes.PRIVACY_POLICY_EXTERNAL}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.MOBILE_APP_DOWNLOAD && (
          <Route
            component={MobileAppDownloadPageWithErrorBoundary}
            exact
            path={routes.MOBILE_APP_DOWNLOAD}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CONTACT_BCOM_BUSINESS && (
          <Route
            component={ContactPageWithErrorBoundary}
            exact
            path={routes.CONTACT_BCOM_BUSINESS}
            props={{
              setIsMenuVisible,
              pageTitle: 'PageTitle.rf.bcombusiness.contact-us.action',
              privacyPolicyTextKey: 'ContactUs.BcomBusiness.Form.Conditions.Label.Text',
            }}
          />
        )}
        {routes.TRAVEL_CONDITIONS_BCOM_BUSINESS && (
          <Route
            component={BcomBusinessTravelConditionsPageWithErrorBoundary}
            exact
            path={routes.TRAVEL_CONDITIONS_BCOM_BUSINESS}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.PACKAGE_TRAVEL_INFO && (
          <Route
            component={PackageTravelInfoPageWithErrorBoundary}
            exact
            path={routes.PACKAGE_TRAVEL_INFO}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CMS_CONTENT && (
          <Route
            component={CmsContentPageWithErrorBoundary}
            exact
            path={`${routes.CMS_CONTENT}/:parent?/:slug`}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.REFUNDS && (
          <Route
            component={RefundsPageWithErrorBoundary}
            exact
            path={routes.REFUNDS}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.DESTINATION && (
          <Route
            component={DestinationPageWithErrorBoundary}
            exact
            path={routes.DESTINATION}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.YOUR_SELECTED_TRIP && (
          <Route
            component={YourSelectedTripPageWithErrorBoundary}
            exact
            path={routes.YOUR_SELECTED_TRIP}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.NEW_BRAND_REDIRECTION && (
          <Route
            component={NewBrandRedirectionPageWithErrorBoundary}
            exact
            path={routes.NEW_BRAND_REDIRECTION}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.MOBILE_TRAVEL_PLAN_LOGIN && (
          <Route
            component={MtpLoginPageWithErrorBoundary}
            exact
            path={routes.MOBILE_TRAVEL_PLAN_LOGIN}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.MOBILE_TRAVEL_PLAN_FLIGHT_STATUS && (
          <Route
            component={MtpFlightStatusPageWithErrorBoundary}
            exact
            path={routes.MOBILE_TRAVEL_PLAN_FLIGHT_STATUS}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.MOBILE_TRAVEL_PLAN && (
          <Route
            component={MtpSelectFlightPageWithErrorBoundary}
            exact
            path={routes.MOBILE_TRAVEL_PLAN}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CHALLENGE && (
          <Route
            component={ChallengePageWithErrorBoundary}
            exact
            path={routes.CHALLENGE}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CARRIER_FLIGHT_CONFIRMATION && (
          <Route
            component={CFCReviewPageWithErrorBoundary}
            exact
            path={routes.CARRIER_FLIGHT_CONFIRMATION}
            props={{ setIsMenuVisible }}
          />
        )}
        {routes.CFC_PAGE_STEP_TWO && (
          <Route
            component={CFCIframePageWithErrorBoundary}
            exact
            path={routes.CFC_PAGE_STEP_TWO}
            props={{ setIsMenuVisible }}
          />
        )}
        <Route component={PageNotFoundWithErrorBoundary} path="*" props={{ setIsMenuVisible }} />
      </Switch>
    </>
  );
};

export default Content;

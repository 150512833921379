/* eslint-disable jsx-a11y/label-has-associated-control */
import { vars, Stack } from '@etg/wings';
import { CaretDown } from '@phosphor-icons/react';
import { css, cx } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { useLanguage } from '../custom-hooks/language';

interface LanguageProps {
  id: string;
  code: string;
  name: string;
}

interface LanguageSelectionDropdownProps {
  isShownInFooter?: boolean;
  languageDropdownStyles?: string;
}

const selectWrapperStyles = css`
  position: relative;
`;

const labelStyles = (isShownInFooter: boolean) => css`
  color: ${isShownInFooter ? vars.colors.footer.text : vars.colors.header.content};
  font-size: 0.875rem;
  line-height: 1.25;
  padding: 0 8px;
`;

const selectStyles = css`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid ${vars.colors.inputs.border};
  border-radius: 3px;
  color: ${vars.colors.text};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 48px;
  justify-content: space-between;
  min-height: 38px;
  padding: 0 16px;
  position: relative;
  width: 100%;
`;

const arrowStyles = css`
  inset-block-start: 16px;
  inset-inline-end: 16px;
  position: absolute;
`;

const LanguageSelectionDropdown = ({
  languageDropdownStyles,
  isShownInFooter,
}: LanguageSelectionDropdownProps) => {
  const { t } = useTranslation();
  const { availableLanguages, currentLanguage, changeLanguage } = useLanguage();

  return (
    <Stack spacing={8}>
      <label className={labelStyles(Boolean(isShownInFooter))} htmlFor="language-select">
        {t('SiteHeader.Language.Label')}
      </label>
      <div className={selectWrapperStyles}>
        <select
          aria-label={t('Receipt.ReceiptDetails.PrintModal.SelectLanguageHeading')}
          className={cx(selectStyles, languageDropdownStyles)}
          data-testid="language-selector-dropdown"
          id="language-select"
          name="language"
          onChange={changeLanguage}
          title={t('Receipt.ReceiptDetails.PrintModal.SelectLanguageHeading')}
          value={currentLanguage.id}
        >
          {availableLanguages.map((language: LanguageProps) => (
            <option key={language.id} title={language.code} value={language.id}>
              {t(`MultiLanguage.Languages.${language.code}`) || language.name}
            </option>
          ))}
        </select>
        <CaretDown
          className={arrowStyles}
          color={vars.colors.text}
          data-testid="caret"
          size={18}
          weight="light"
        />
      </div>
    </Stack>
  );
};

export default LanguageSelectionDropdown;

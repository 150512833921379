import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import { CHECK_IN_BAGGAGE, SECOND_BAGGAGE } from '../../../../products';
import { setSecondBagValueForTraveler } from '../../utils/secondBagUtils';
import ApplySelectionToAllCheckbox from '../../../common/components/ApplySelectionToAllCheckbox';
const mapDispatchToProps = (dispatch, props) => {
  return {
    setSecondBagValueInCheckedBag: setSecondBagValueForTraveler(dispatch, CHECK_IN_BAGGAGE.name, props),
    setSecondBagValueInSecondBag: setSecondBagValueForTraveler(dispatch, SECOND_BAGGAGE.name, props)
  };
};
const onChangeAll = (dispatch, _ref) => {
  let {
    form,
    firstTravelerSelectionValue,
    productName,
    secondBagTravelers,
    orderSecondBag
  } = _ref;
  return checkboxValueAll => {
    dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, checkboxValueAll));
    const selectionValue = checkboxValueAll ? Boolean(firstTravelerSelectionValue) : null;
    const {
      setSecondBagValueInCheckedBag,
      setSecondBagValueInSecondBag
    } = mapDispatchToProps(dispatch, {
      orderSecondBag
    });
    secondBagTravelers === null || secondBagTravelers === void 0 || secondBagTravelers.slice(1).forEach(traveler => {
      setSecondBagValueInCheckedBag(form, traveler, selectionValue);
      setSecondBagValueInSecondBag(form, traveler, selectionValue);
      if (firstTravelerSelectionValue !== null || !checkboxValueAll) {
        dispatch(changeFormValue(form, `${productName}.isSelectedSecondBaggage-traveler-${traveler.id}`, checkboxValueAll ? firstTravelerSelectionValue : false));
      }
    });
  };
};
export const mergeProps = (dispatch, ownProps) => {
  return {
    onChangeAll: onChangeAll(dispatch, ownProps)
  };
};
export const mapStateToProps = (state, _ref2) => {
  let {
    productName,
    form
  } = _ref2;
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  return {
    shouldApplySelectionToAll: shouldApplySelectionToAll !== null && shouldApplySelectionToAll !== void 0 ? shouldApplySelectionToAll : false
  };
};
export default connect(mapStateToProps, mergeProps)(ApplySelectionToAllCheckbox);
import { constants } from '@eti/utils';
import { getTravelersPrimaryFlow } from './checkInBaggageUtils';
export const useSecondBaggage = _ref => {
  var _orderSecondBag$sellS, _orderSecondBag$sellS2;
  let {
    travelerNumber,
    orderSecondBag
  } = _ref;
  const secondBagTravelers = getTravelersPrimaryFlow(orderSecondBag);
  const travelersWithoutInfantsSecondBag = secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.filter(_ref2 => {
    let {
      ageType
    } = _ref2;
    return ageType !== constants.ageTypes.INFANT;
  });
  const secondBagTraveler = travelersWithoutInfantsSecondBag && travelersWithoutInfantsSecondBag[travelerNumber];
  const {
    maxWeight: weightOfSecondBag = 0,
    numberOfUnits: piecesOfSecondBag = 0,
    weightUnit: weightUnitOfSecondBag = ''
  } = (_orderSecondBag$sellS = orderSecondBag === null || orderSecondBag === void 0 || (_orderSecondBag$sellS2 = orderSecondBag.sellSpecification) === null || _orderSecondBag$sellS2 === void 0 || (_orderSecondBag$sellS2 = _orderSecondBag$sellS2.sellPriceBaggage) === null || _orderSecondBag$sellS2 === void 0 ? void 0 : _orderSecondBag$sellS2[0]) !== null && _orderSecondBag$sellS !== void 0 ? _orderSecondBag$sellS : {};
  return {
    secondBagTraveler,
    weightOfSecondBag,
    piecesOfSecondBag,
    weightUnitOfSecondBag,
    secondBagTravelers: travelersWithoutInfantsSecondBag
  };
};
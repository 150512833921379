import { UPSELL_TYPES } from './constants';
const parseUpsell = str => {
  try {
    var _parsedJSON$content;
    const parsedJSON = JSON.parse(str);
    if (!(typeof parsedJSON === 'object' && parsedJSON !== null)) {
      throw new Error('Upsell without type');
    }
    return {
      ...parsedJSON,
      content: (_parsedJSON$content = parsedJSON.content) === null || _parsedJSON$content === void 0 ? void 0 : _parsedJSON$content.trim()
    };
  } catch (error) {
    return {
      content: str.trim(),
      type: UPSELL_TYPES.INFOMATION
    };
  }
};
export const formatUpsell = upsellText => upsellText ? parseUpsell(upsellText) : undefined;
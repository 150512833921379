import { formValueSelector } from 'redux-form';
import { getPriceDivided, getTotalMarkup, getTotalPrice } from '../../../common/utils/price';
export const getPricePerBound = _ref => {
  let {
    numberOfBounds,
    priceRaw,
    showPricePerBound
  } = _ref;
  const price = showPricePerBound ? getPriceDivided(priceRaw, numberOfBounds) : priceRaw;
  if (!price) {
    return 0;
  }
  return price;
};
export const getMarkupPerBound = _ref2 => {
  let {
    markup,
    numberOfBounds,
    showPricePerBound
  } = _ref2;
  const markupPrice = showPricePerBound ? getPriceDivided(markup, numberOfBounds) : markup;
  if (!markupPrice) {
    return 0;
  }
  return markupPrice;
};
export const getMinPrice = function (travelers) {
  if (travelers === void 0) {
    travelers = [];
  }
  return travelers.reduce((minPrice, _ref3) => {
    let {
      price
    } = _ref3;
    if (!price || !Number.isFinite(price)) {
      return minPrice;
    }
    return minPrice ? Math.min(price, minPrice) : price;
  }, null);
};
export const getPrice = (name, form) => state => {
  const selection = formValueSelector(form)(state, name);
  if (selection && selection !== null && selection !== void 0 && selection.value && Array.isArray(selection.value)) {
    return selection.value.reduce((totalPrice, _ref4) => {
      let {
        priceRaw = 0
      } = _ref4;
      return totalPrice + priceRaw;
    }, 0);
  }
  return getTotalPrice(form, name)(state);
};
export const getMarkup = (name, form) => state => getTotalMarkup(form, name)(state);
import { Heading, vars } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { Airplane, X } from '@phosphor-icons/react';

const wrapperStyles = css`
  background-color: ${vars.colors.signal.information.tint};
  position: relative;
  width: 100%;
`;

const toggleTripInfoButtonStyles = css`
  padding: 16px;
  padding-inline-end: 40px;
  text-align: start;
  width: 100%;
`;

const closeButtonStyles = css`
  display: flex;
  inset-inline-end: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  & div {
    border-radius: 3px;
    display: flex;
    padding: 4px;
    position: relative;

    &::after {
      border: 2px solid ${vars.colors.focusRing};
      border-radius: 6px;
      content: '';
      height: calc(100% + 4px);
      left: -2px;
      opacity: 0;
      position: absolute;
      top: -2px;
      transition: opacity 0.2s ease-in-out;
      width: calc(100% + 4px);
      z-index: -1;
    }
  }

  &:hover div {
    background-color: rgb(53 134 209 / 10%);
  }

  &:focus-visible div {
    background-color: rgb(53 134 209 / 10%);

    &::after {
      opacity: 1;
    }
  }
`;

interface TripReminderHeaderProps {
  onDiscardReminder: () => void;
  onToggleTripContents: () => void;
}

const TripReminderHeader = ({
  onDiscardReminder,
  onToggleTripContents,
}: TripReminderHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={wrapperStyles}>
      <button
        className={cx(resetButtonStyle, toggleTripInfoButtonStyles)}
        data-testid="tripReminder-toggleContentButton"
        onClick={onToggleTripContents}
        title={t('SiteHeader.ToggleMenu.Label')}
        type="button"
      >
        <Heading as="h3" iconPrefix={<Airplane weight="fill" />} level={4}>
          {t('TripReminder.Heading')}
        </Heading>
      </button>
      <button
        className={cx(resetButtonStyle, closeButtonStyles)}
        data-testid="tripReminder-discardButton"
        onClick={onDiscardReminder}
        title={t('TripDetails.CloseButton')}
        type="button"
      >
        <div>
          <X color="#262626" size={24} weight="light" />
        </div>
      </button>
    </div>
  );
};

export default TripReminderHeader;

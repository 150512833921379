import { css, mediaQueries } from '@eti/styles';
import { standardGrid } from '../../../common/main-layout/styles';
export const checkInBaggagePostbookingStyles = () => {
  const checkInBaggageProductLayoutStyles = readMoreContent => css`
    ${standardGrid}

    .cta {
      grid-area: ${readMoreContent ? '4 / 2 / 5 / 3' : '3 / 2 / 5 / 3'};
    }
  `;
  const checkInBaggageBodyGridLayoutStyles = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);

    .additionalInformation {
      grid-area: 1 / 1 / 2 / 4;
      margin-bottom: 16px;
    }

    .promotionalText {
      grid-area: 5 / 1 / 5 / 4;
      margin-top: 16px;
    }
  `;
  const checkInBaggageCtaGridLayoutStyles = css`
    column-gap: 8px;
    display: grid;
    grid-template-columns: 2fr auto;
    grid-template-rows: repeat(3, auto);

    @media ${mediaQueries.medium.up} {
      justify-content: unset;
    }

    .priceLabel {
      grid-area: 4 / 1 / 4 / 2;

      @media ${mediaQueries.small.only} {
        grid-area: 4 / 1 / 5 / 4;
      }
    }

    .price {
      grid-area: 5 / 1 / 5 / 2;
      max-height: 18px;

      @media ${mediaQueries.small.only} {
        grid-area: 5 / 3 / 6 / 4;
      }
    }
  `;
  const weightDescriptionGridStyle = css`
    grid-area: 2 / 1 / 3 / 4;
  `;
  const notificationWrapperStyles = css`
    display: grid;
    grid-area: 4 / 1 / 5 / 4;
    margin-top: 16px;
  `;
  const connectedSelectBoxStyles = css`
    display: grid;
    grid-area: 2 / 2 / 6 / 4;

    label {
      margin-inline-start: 0;
    }

    @media ${mediaQueries.small.only} {
      grid-area: 3 / 1 / 3 / 4;
    }

    @media ${mediaQueries.medium.up} {
      width: 320px;
    }

    @media ${mediaQueries.large.up} {
      width: 272px;
    }

    @media ${mediaQueries.xlarge.up} {
      width: 336px;
    }
  `;
  const selectBoxOptionListStyle = css`
    li:first-child {
      font-weight: 800;
    }
  `;
  const selectBoxOptionLabelWrapperStyle = css`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  `;
  const selectBoxOptionLabelTextStyle = css`
    white-space: nowrap;
  `;
  return {
    checkInBaggageProductLayoutStyles,
    checkInBaggageBodyGridLayoutStyles,
    checkInBaggageCtaGridLayoutStyles,
    weightDescriptionGridStyle,
    notificationWrapperStyles,
    connectedSelectBoxStyles,
    selectBoxOptionListStyle,
    selectBoxOptionLabelWrapperStyle,
    selectBoxOptionLabelTextStyle
  };
};
import { CHECK_IN_BAGGAGE } from '../../products';
import { getMarkup, getMinPrice, getPrice } from '../common/utils/priceUtil';
import { getNumberOfUnits, getSelected, getSelectedForTraveler } from '../common/utils/units';
import getIcon from './utils/icon';
import { getGraphQlSelection } from './utils/getGraphQlSelection';
import CheckInBaggage from './containers/CheckInBaggageContainer';
import CheckInBaggagePersuasionModal from './containers/CheckInBaggagePrimaryFlowPersuasionModalContainer';
import NotAvailableCheckInBaggage from './components/NotAvailableCheckInBaggage';
const {
  id,
  name: formSection
} = CHECK_IN_BAGGAGE;
const checkInBaggage = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(String(id), formSection),
  getIcon,
  getMarkup: getMarkup.bind(null, formSection),
  getMinPrice,
  getNumberOfUnits: getNumberOfUnits.bind(null, formSection),
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getSelectedForTraveler: getSelectedForTraveler.bind(null, formSection),
  ...CHECK_IN_BAGGAGE
};
export { checkInBaggage, CheckInBaggage, CheckInBaggagePersuasionModal, NotAvailableCheckInBaggage };
import { formValueSelector } from 'redux-form';
import { isPrimaryFlow } from '../../../common/utils/primaryFlow';
const isValidTraveler = traveler => {
  return typeof traveler === 'object' && (traveler.value === true || traveler.value === 'true' || traveler.value === 'combined') && traveler.included !== true;
};
export const getGraphQlSelection = (productId, formSection) => {
  return _ref => {
    var _selectedTravelers;
    let {
      form,
      state
    } = _ref;
    const formData = formValueSelector(form)(state, formSection) || {};
    let selectedTravelers;
    if (!isPrimaryFlow()) {
      var _formData$value;
      selectedTravelers = formData === null || formData === void 0 || (_formData$value = formData.value) === null || _formData$value === void 0 || (_formData$value = _formData$value.filter(_ref2 => {
        let {
          exclude
        } = _ref2;
        return !exclude;
      })) === null || _formData$value === void 0 ? void 0 : _formData$value.map(_ref3 => {
        let {
          value
        } = _ref3;
        return {
          travelerId: value,
          numberOfUnits: 1
        };
      });
    } else {
      selectedTravelers = Object.values(formData).filter(isValidTraveler).map(_ref4 => {
        let {
          id
        } = _ref4;
        return {
          travelerId: String(id),
          numberOfUnits: 1
        };
      });
    }
    return (_selectedTravelers = selectedTravelers) !== null && _selectedTravelers !== void 0 && _selectedTravelers.length ? {
      productId,
      selectionTravelerNumberOfUnits: selectedTravelers
    } : null;
  };
};
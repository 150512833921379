import { change as changeFormValue } from 'redux-form';
import { connect } from 'react-redux';
import { SIMPLE_VISA } from '../../products';
import { getSelected, getDisplayPrice, getTravelerField, getTravelersWithChoice, hasAnyTravelerSelected, getSelectedTraveler, getTravelerChoice } from '../utils/selection';
import { getTravelerNames } from '../../common/utils/travelerNames';
import { getContactEmail } from '../../common/utils/travelerContacts';
import { getTravelers } from '../utils/simpleVisaTransformer';
import SimpleVisa from '../components/SimpleVisa';
const getCardSelection = cardSelectionRaw => {
  if (typeof cardSelectionRaw === 'string') {
    return cardSelectionRaw === 'true';
  }
  if (typeof cardSelectionRaw === 'boolean') {
    return cardSelectionRaw === true;
  }
  return null;
};
const updateTotalPrice = (dispatch, _ref) => {
  let {
    form
  } = _ref;
  return priceRaw => {
    dispatch(changeFormValue(form, `${SIMPLE_VISA.name}.value.priceRaw`, priceRaw));
  };
};
const selectVisaTraveler = (id, form, value, dispatch) => {
  const travelerField = getTravelerField(id, SIMPLE_VISA.name);
  dispatch(changeFormValue(form, `${travelerField}.selected`, value));
};
const selectAllTravelers = (dispatch, _ref2) => {
  let {
    form
  } = _ref2;
  return (travelers, value) => {
    const selectedValue = Boolean(value);
    travelers.forEach(_ref3 => {
      let {
        id,
        hasChoice
      } = _ref3;
      if (hasChoice) {
        selectVisaTraveler(id, form, selectedValue, dispatch);
      }
    });
  };
};
const selectProduct = (dispatch, _ref4) => {
  let {
    form
  } = _ref4;
  return newValue => dispatch(changeFormValue(form, `${SIMPLE_VISA.name}.value.value`, newValue));
};
const selectTraveler = (dispatch, _ref5) => {
  let {
    form
  } = _ref5;
  return (id, value) => {
    selectVisaTraveler(id, form, value, dispatch);
  };
};
const mapStateToProps = (state, _ref6) => {
  let {
    form,
    order,
    contactEmail,
    travelerLabel
  } = _ref6;
  const {
    availableChoices
  } = order.sellSpecification || {};
  const travelerNames = getTravelerNames(form, state, order.travelers);
  const travelersWithChoice = getTravelersWithChoice(SIMPLE_VISA.name, form)(state);
  const travelers = getTravelers(order, travelerNames, travelerLabel, availableChoices).map(traveler => {
    const isSelected = getSelectedTraveler(SIMPLE_VISA.name, form, traveler.id)(state);
    const choice = getTravelerChoice(SIMPLE_VISA.name, form, traveler.id)(state);
    const hasChoice = travelersWithChoice.includes(traveler.id);
    return {
      ...traveler,
      hasChoice,
      isSelected,
      choice
    };
  });
  return {
    cardSelection: getCardSelection(getSelected(SIMPLE_VISA.name, form)(state)),
    contactEmail: getContactEmail(form, state) || contactEmail,
    hasAnySelected: hasAnyTravelerSelected(SIMPLE_VISA.name, form)(state),
    priceRaw: getDisplayPrice(SIMPLE_VISA.name, form)(state),
    travelers,
    isSelected: travelers.some(traveler => traveler.isSelected)
  };
};
const mapDispatchToProps = (dispatch, form) => ({
  selectProduct: selectProduct(dispatch, form),
  selectAllTravelers: selectAllTravelers(dispatch, form),
  updateTotalPrice: updateTotalPrice(dispatch, form),
  selectTraveler: selectTraveler(dispatch, form)
});
export default connect(mapStateToProps, mapDispatchToProps)(SimpleVisa);
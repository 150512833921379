import { price as priceUtils } from '@eti/utils';
import { change as changeFormValue } from 'redux-form';
import { getPriceDivided as getPricePerBound } from '../../../common/utils/price';
export const getSecondBagTravelers = orderSecondBag => {
  var _orderSecondBag$sellS, _orderSecondBag$sellS2, _orderSecondBag$trave;
  const baggageSpecification = (_orderSecondBag$sellS = (_orderSecondBag$sellS2 = orderSecondBag.sellSpecification) === null || _orderSecondBag$sellS2 === void 0 ? void 0 : _orderSecondBag$sellS2.sellPriceBaggage) !== null && _orderSecondBag$sellS !== void 0 ? _orderSecondBag$sellS : [];
  return (_orderSecondBag$trave = orderSecondBag.travelers) === null || _orderSecondBag$trave === void 0 ? void 0 : _orderSecondBag$trave.map(traveler => {
    var _price$markup, _price$price;
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      id: traveler.id || '',
      markup: price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value,
      price: price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value
    };
  });
};
export const getSecondBagPrice = (shouldShowPricePerBoundSecondBag, numberOfBounds, currentSecondBagTraveler) => {
  return priceUtils.formatPrice(shouldShowPricePerBoundSecondBag ? getPricePerBound(currentSecondBagTraveler === null || currentSecondBagTraveler === void 0 ? void 0 : currentSecondBagTraveler.price, numberOfBounds) : currentSecondBagTraveler === null || currentSecondBagTraveler === void 0 ? void 0 : currentSecondBagTraveler.price);
};
export const setSecondBagValueForTraveler = (dispatch, productName, _ref) => {
  let {
    orderSecondBag
  } = _ref;
  return (form, secondBagTraveler, checkboxvalue) => {
    var _orderSecondBag$sellS3, _orderSecondBag$sellS4;
    const {
      id: secondBagId,
      markup: secondBagMarkup,
      price: secondBagPrice
    } = secondBagTraveler || {};
    const {
      maxWeight: weightOfSecondBag,
      numberOfUnits: piecesOfSecondBag,
      weightUnit: weightUnitOfSecondBag
    } = (_orderSecondBag$sellS3 = orderSecondBag === null || orderSecondBag === void 0 || (_orderSecondBag$sellS4 = orderSecondBag.sellSpecification) === null || _orderSecondBag$sellS4 === void 0 || (_orderSecondBag$sellS4 = _orderSecondBag$sellS4.sellPriceBaggage) === null || _orderSecondBag$sellS4 === void 0 ? void 0 : _orderSecondBag$sellS4[0]) !== null && _orderSecondBag$sellS3 !== void 0 ? _orderSecondBag$sellS3 : {};
    dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${secondBagId}`, {
      value: checkboxvalue !== null && checkboxvalue !== void 0 ? checkboxvalue : null,
      included: true,
      id: secondBagId,
      markup: checkboxvalue ? secondBagMarkup : null,
      priceRaw: checkboxvalue ? secondBagPrice : 0,
      piecesOfSecondBag,
      weightOfSecondBag,
      weightUnitOfSecondBag
    }));
  };
};